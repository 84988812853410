.App-logo {
  max-height: 498px;
  max-width: 160px;
}

.Sidebar-logo {
  position: absolute;
  color:#80FF00;
  mix-blend-mode: hard-light;
  right: 32px;
  width: 32px;
  height: 100vh;
  z-index: 3;
  opacity: 95%;
}

.App-textBox {
  padding: 32px 0px 64px 0px;
  width: 80%;
  text-wrap: balance;
}

.App-imageBox {
  position: relative;
  height: 360px;
}

.App-imageBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: calc(100% + 32px);
}

.Flex-Left {
  align-self: flex-start;
  margin-left: -32px;
}

.Flex-Right {
  align-self: flex-end;
  margin-right: -32px;
}

.App-imageBoxText {
  position: absolute;
  bottom: -9px;
  color:#80FF00;
  mix-blend-mode: hard-light;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 40px;
  max-width: 60%;
  opacity: 90%;
}

.ImageTextFlex-Right {
  left: 0px;
}

.ImageTextFlex-Left {
  left: 32px;
}
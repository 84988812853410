.App {
  background-color: #8000FF;
  color: white;
  display: flex;
  flex-direction: column;
}

.App-box {
  max-width: 360px;
  padding: 32px;
  align-self: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.App-link {
  color: #80FF00;
  cursor: pointer;
}
body {
  margin: 0;
  font-family: 'Atkinson Hyperlegible', -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.atkinson-hyperlegible-regular {
  font-family: "Atkinson Hyperlegible", sans-serif;
  font-weight: 400;
  font-size: 16;
  font-style: normal;
}

.atkinson-hyperlegible-bold {
  font-family: "Atkinson Hyperlegible", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.atkinson-hyperlegible-regular-italic {
  font-family: "Atkinson Hyperlegible", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.atkinson-hyperlegible-bold-italic {
  font-family: "Atkinson Hyperlegible", sans-serif;
  font-weight: 700;
  font-style: italic;
}